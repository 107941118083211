
import { h, defineComponent, ref, Component } from 'vue'
import {
  NIcon,
  NLayoutSider,
  NLayout,
  NSpace,
  NMenu,
  NLayoutContent,
  NLayoutHeader,
  MenuOption,
  useMessage,
} from 'naive-ui'
import {
  ListOutline as ListIcon,
  AppsOutline as AppsIcon,
  FileTrayStackedOutline as FileTrayStackedIcon,
  GlobeOutline as GlobeIcon,
  ListCircleOutline as ListCircleIcon,
  ExitOutline,
  DocumentAttach,
} from '@vicons/ionicons5'

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

const menuOptions: MenuOption[] = [
  {
    label: 'Sites',
    key: 'sites',
    icon: renderIcon(GlobeIcon),
  },
  {
    label: 'Grupos',
    key: 'grupos',
    icon: renderIcon(AppsIcon),
  },
  {
    label: 'Subgrupos',
    key: 'subgrupos',
    icon: renderIcon(ListIcon),
  },
  {
    label: 'Site/Subgrupos',
    key: 'site-subgrupos',
    icon: renderIcon(ListCircleIcon),
  },
  {
    label: 'Arquivos',
    key: 'home',
    icon: renderIcon(FileTrayStackedIcon),
  },
  {
    label: 'Migração',
    key: 'migracao',
    icon: renderIcon(DocumentAttach),
  },
  {
    label: 'Sair',
    key: 'sair',
    icon: renderIcon(ExitOutline),
  },
]
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    NLayoutSider,
    NLayoutContent,
    NLayout,
    NSpace,
    NMenu,
    NLayoutHeader,
    NIcon,
  },
  setup() {
    const router = useRouter()
    const message = useMessage()

    function onPageChange(key: string, item: MenuOption) {
      switch (key) {
        case 'sair':
          localStorage.removeItem('user')
          message.warning('Você escolheu sair do sistema')
          router.push('/')
          break
        default:
          router.push(`/${key}`)
      }
    }

    return {
      menuOptions,
      onPageChange,
    }
  },
})
