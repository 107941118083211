import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/Default.layout.vue'
import SideBarLayout from '@/layouts/SideBar.layout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Auth',
    component: () => import('@/views/auth/Auth.page.vue'),
    meta: {
      layout: DefaultLayout,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/Home.page.vue'),
    meta: {
      layout: SideBarLayout,
    },
  },
  {
    path: '/sites',
    name: 'Site',
    component: () => import('@/views/sites/Sites.page.vue'),
    meta: {
      layout: SideBarLayout,
    },
  },
  {
    path: '/migracao',
    name: 'Migracao',
    component: () => import('@/views/migration/Migration.page.vue'),
    meta: {
      layout: SideBarLayout,
    },
  },
  {
    path: '/grupos',
    name: 'Grupos',
    component: () => import('@/views/grupos/Grupos.page.vue'),
    meta: {
      layout: SideBarLayout,
    },
  },
  {
    path: '/subgrupos',
    name: 'Subgrupos',
    component: () => import('@/views/subgrupos/Subgrupos.page.vue'),
    meta: {
      layout: SideBarLayout,
    },
  },
  {
    path: '/site-subgrupos',
    name: 'SiteSubgrupos',
    component: () => import('@/views/site_subgrupos/SiteSubgrupos.page.vue'),
    meta: {
      layout: SideBarLayout,
    },
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
export default router
