import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "app w-full min-h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_message_provider, null, {
      default: _withCtx(() => [
        _createVNode(_component_n_loading_bar_provider, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout || 'div')))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}