
import { defineComponent } from 'vue'
import { NMessageProvider, NLoadingBarProvider } from 'naive-ui'

export default defineComponent({
  name: 'App',
  components: {
    NMessageProvider,
    NLoadingBarProvider
  },
})
